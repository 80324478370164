import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-50e7d26d"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "nav-head"
};
const _hoisted_2 = {
  class: "flex"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "flex-right"
};
const _hoisted_5 = ["src"];
export function render(_ctx, _cache) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");

  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");

  const _component_el_dropdown = _resolveComponent("el-dropdown");

  const _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createElementBlock("nav", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    src: _ctx.logo,
    style: {
      "height": "40px",
      "width": "40px"
    }
  }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_dropdown, {
    trigger: "click",
    onCommand: _ctx.handleSelect
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
        command: "en"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang English")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "ja"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang Japanese")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "ko"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang Korean")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "hi"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang Hindi")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "ru"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang Russian")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "ge"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang German")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "fr"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang French")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "po"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang Portuguese")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "sp"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang Spanish")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "ar"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang Arabic")), 1)]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "zh-TW"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Lang Chinese")), 1)]),
        _: 1
      })]),
      _: 1
    })]),
    default: _withCtx(() => [_createElementVNode("span", null, [_createTextVNode(_toDisplayString(_ctx.filterLanguage(_ctx.$i18n.locale)), 1), _createElementVNode("img", {
      src: _ctx.iconArrowDown,
      style: {
        "height": "16px",
        "width": "16px",
        "vertical-align": "middle",
        "margin-right": "8px"
      }
    }, null, 8, _hoisted_5)])]),
    _: 1
  }, 8, ["onCommand"]), !_ctx.shortAddress ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    type: "primary",
    size: "default",
    class: "head-btn",
    round: "",
    onClick: _ctx.handleLogin
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("Connect Wallet")), 1)]),
    _: 1
  }, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    type: "primary",
    size: "default",
    class: "head-btn",
    round: ""
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.shortAddress), 1)]),
    _: 1
  }))])]);
}
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-36ccab4d"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "flex"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
export function render(_ctx, _cache) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass({
      'bottom-nav': true,
      isActive: _ctx.activeRoute == '/'
    }),
    onClick: _cache[0] || (_cache[0] = () => _ctx.handleRoute('/'))
  }, [_ctx.activeRoute == '/' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _ctx.footerTab1Active
  }, null, 8, _hoisted_2)) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _ctx.footerTab1
  }, null, 8, _hoisted_3)), _createTextVNode(" " + _toDisplayString(_ctx.$t("Mint")), 1)], 2), _createElementVNode("div", {
    class: _normalizeClass({
      'bottom-nav': true,
      isActive: _ctx.activeRoute == '/me'
    }),
    onClick: _cache[1] || (_cache[1] = () => _ctx.handleRoute('/me'))
  }, [_ctx.activeRoute == '/me' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _ctx.footerTab2Active
  }, null, 8, _hoisted_4)) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _ctx.footerTab2
  }, null, 8, _hoisted_5)), _createTextVNode(" " + _toDisplayString(_ctx.$t("Profile")), 1)], 2)], 512)), [[_vShow, _ctx.showBNav]]);
}
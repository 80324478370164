import Web3 from "web3";
import { Eip838ExecutionError } from "web3";
import * as Web3EthAbi from "web3-eth-abi";

import { ElMessage } from "element-plus";
import Log from "../utils/log";

import { OXCConf } from "./constant";

const {
    InvestAIAddress,
    AITokenAddress,
    AIIEOAddress,
    AIICOAddress,
    UsdtAddress,
    DaoAddress,
    DefaultUserAddress,
} = OXCConf;

import { ERC20ABI, ERC721ABI, InvestAI, AIAbi, AIIDOABI } from "./abis/index";
import BigNumber from "bignumber.js";

const prefix = "background: green; color: #fff; border-radius: 3px 0 0 3px;";
const suffix = "color: blue";

const maxApprove =
    "115792089237316195423570985008687907853269984665640564039457584007913129639935";

function parseInnerError(e, contract) {
    if (e?.innerError?.errorSignature) {
        return e.innerError;
    }

    if (!e.innerError) {
        if (e?.data?.data.startsWith("0x")) {
            e.innerError = new Eip838ExecutionError(e.data);
        }
    }

    if (e.innerError && !(e.innerError instanceof Eip838ExecutionError)) {
        if (e?.data?.data.startsWith("0x")) {
            //e.data.data = "0x5f201281";
            e.innerError = new Eip838ExecutionError(e.data);
        }
    }

    if (
        typeof e?.innerError?.data === "string" &&
        e?.innerError?.data?.startsWith("0x")
    ) {
        Web3EthAbi.decodeContractErrorData(
            contract._errorsInterface,
            e.innerError
        );
    }

    if (
        typeof e?.innerError?.data?.data === "string" &&
        e?.innerError?.data?.data?.startsWith("0x")
    ) {
        Web3EthAbi.decodeContractErrorData(
            contract._errorsInterface,
            e.innerError.data
        );
    }
    return e.innerError;
}

// const KEYS = [
//     "to",
//     "from",
//     "nonce",
//     "gas",
//     "gasPrice",
//     "maxPriorityFeePerGas",
//     "maxFeePerGas",
//     "data",
//     "value",
//     "chainId",
//     "type",
//     "accessList",
// ];
// async function parseTxError(txHash, web3, contract) {
//     const tx = await web3.eth.getTransaction(txHash);
//     const request = {};
//     KEYS.forEach((key) => {
//         request[key] = tx[key];
//     });
//     if (request.gasPrice) {
//         delete request.maxPriorityFeePerGas;
//         delete request.maxFeePerGas;
//     }
//     try {
//         const result = await web3.eth.call(request, tx.blockNumber);
//         console.log(result);
//         return null;
//     } catch (e) {
//         return parseInnerError(e, contract);
//     }
// }

class Wallet {
    constructor() {
        this.abiMap = {
            erc20: ERC20ABI,
            erc721: ERC721ABI,
            usdt: ERC20ABI,
            dao: ERC20ABI,
            ai: AIAbi,
            investAI: InvestAI,
            aiIeo: AIIDOABI,
            aiIco: AIIDOABI,
        };
        this.initContracts();
        this.checkChain();
        this.chainActive = true;
    }

    getABISerialization(ABIJson) {
        let map = new Object();
        for (let i = 0; i < ABIJson.length; i++) {
            map[ABIJson[i].name] = ABIJson[i];
        }
        return map;
    }

    encodeCryptoData({ contract, func, args = [] }) {
        const ABI = this.abiMap[contract];

        const f = this.getABISerialization(ABI)[func];

        const data = Web3EthAbi.encodeFunctionCall(f, args);

        return data;
    }

    checkChain() {
        const eth = window.ethereum;

        const chainId = eth.chainId ? parseInt(eth.chainId) : 0;
        Log.error(" ==== initAccount chain id sync ==== ", chainId);

        eth.request({ method: "eth_chainId" }).then((metaChainId) => {
            Log.error(
                " ==== initAccount chain id async ==== ",
                parseInt(metaChainId)
            );

            if (parseInt(metaChainId) !== 56 && parseInt(metaChainId) !== 97) {
                ElMessage.error({
                    message:
                        "Current chain is not support ! Please switch to BSC NET!",
                    duration: 0,
                    offset: 10,
                });
                this.chainActive = false;
                return Promise.reject(
                    "Current chain is not support ! Please switch to BSC NET!"
                );
            }
        });
    }

    initContracts() {
        const eth = window.ethereum;

        if (!eth) {
            Log.error(
                " Current browser is not support ! window.eth  is not undefined!"
            );
            return Promise.reject(
                new Error(
                    " Current browser is not support ! window.eth  is not undefined!"
                )
            );
        }

        eth.on("accountsChanged", function () {
            location.reload();
        });
        // eth.on('connect', () => location.reload())
        // eth.on('chainChanged', () => location.reload())

        this.web3JS = this.web3JS ? this.web3JS : new Web3(eth);

        const USDTContract = new this.web3JS.eth.Contract(
            ERC20ABI,
            UsdtAddress
        );
        const DAOContract = new this.web3JS.eth.Contract(ERC20ABI, DaoAddress);
        const AIContract = new this.web3JS.eth.Contract(AIAbi, AITokenAddress);
        const InvestAIContract = new this.web3JS.eth.Contract(
            InvestAI,
            InvestAIAddress
        );
        // ai-ieo
        const AIIEOContract = new this.web3JS.eth.Contract(
            AIIDOABI,
            AIIEOAddress
        );

        // ai-ico
        const AIICOContract = new this.web3JS.eth.Contract(
            AIIDOABI,
            AIICOAddress
        );

        this.contractMap = {
            usdt: USDTContract,
            dao: DAOContract,
            ai: AIContract,
            investAI: InvestAIContract,
            aiIeo: AIIEOContract,
            aiIco: AIICOContract,
        };
    }
    initAccount() {
        const eth = window.ethereum;
        if (!eth) {
            return Promise.reject(
                "Current browser is not support ! window.eth  is not undefined"
            );
        }

        this.web3JS = new Web3(eth);

        return this.web3JS.eth
            .getAccounts()
            .then((accounts) => {
                this.defaultMyAddress = accounts[0];
                this.allAddress = accounts;

                return accounts;
            })
            .catch((err) => {
                Log.error(" ==== privilege grant failed! ====", err);
            });
    }

    loginMetamask() {
        const eth = window.ethereum;

        if (!eth) {
            return Promise.reject(
                "Current browser is not support ! window.eth  is not undefined"
            );
        }

        try {
            return eth
                .enable()
                .then((res) => {
                    this.defaultMyAddress = res[0];
                    this.allAddress = res;

                    return res;
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        } catch (error) {
            return Promise.reject(new Error(" User denied account access "));
        }
    }

    errorHandler(err) {
        if (err.code == 4001) {
            Log.error("errorHandler", "User Refused！");
        } else {
            Log.error("errorHandler", err.message);
        }
    }

    async callAbiFunction({
        func = "",
        from = "",
        to = "",
        value = 0,
        args = [],
        contract,
    }) {
        Log.info(
            `%c【 ABI Send START 】%c contract: ${contract}, func: ${func}, args: ${args}, from: ${from}, to: ${to}, values:${value} `,
            prefix,
            suffix
        );

        return this.contractMap[contract].methods[func](...args)
            .send({ from })
            .then((res) => {
                Log.info(
                    `%c【 ABI Send END 】 %c contract: ${contract}, func: ${func}, args: ${args}, res: ${res} ===`,
                    prefix,
                    suffix
                );
                return res;
            })
            .catch((err) => {
                Log.error(
                    `%c【 ABI Send ERROR 】%c contract: ${contract}, func: ${func}, args: ${args},err: ${err}`,
                    prefix,
                    suffix
                );
                console.log(err);
                this.errorHandler(err);
                return Promise.reject(err);
            });
    }

    async callModifyFunction({
        func = "",
        from = "",
        to = "",
        value = 0,
        args = [],
        contract,
    }) {
        Log.info(
            `%c【 MODIFY START 】%c contract: ${contract}, func: ${func}, args: ${args}, from: ${from}, to: ${to}, values:${value} `,
            prefix,
            suffix
        );

        //
        var gas_estimate = 0;
        try {
            gas_estimate = await this.contractMap[contract].methods[func](
                ...args
            ).estimateGas({ from });
        } catch (msg) {
            const error = parseInnerError(msg, this.contractMap[contract]);
            this.errorHandler(new Error(error.errorName));
            return Promise.reject(new Error(error.errorName));
        }

        console.log("gas_estimate", new BigNumber(gas_estimate).toNumber());

        const encodedData = this.encodeCryptoData({ contract, func, args });

        const before = await this.beforeTransaction({
            func,
            from,
            to,
            value,
            args,
            contract,
            gas: gas_estimate,
        });

        if (before && !before.pass) {
            const message = before.error.message;

            const detailString = message.replace(
                "Internal JSON-RPC error.",
                ""
            );

            const ctx = JSON.parse(detailString);

            ElMessage.error(ctx.message);

            return Promise.reject(ctx.message);
        }

        return await this.web3JS.eth
            .sendTransaction({
                from: from ? from : this.defaultMyAddress,
                to: to,
                value,
                data: encodedData,
                // gasPrice: this.web3JS.utils.toWei("5", "gwei"),
                gas: gas_estimate,
            })
            .then((res) => {
                Log.info(
                    `%c【 MODIFY END 】%c contract: ${contract}, func: ${func}, args: ${args}, from: ${from}, to: ${to}, values:${value}, res: ${res}`,
                    prefix,
                    suffix
                );

                return res;
            })
            .catch((err) => {
                Log.info(
                    `%c【 MODIFY END ERROR 】%c contract: ${contract}, func: ${func}, args: ${args}, from: ${from}, to: ${to}, values:${value}, err: ${JSON.stringify(
                        err
                    )}`,
                    prefix,
                    suffix
                );
                this.errorHandler(err);
                return Promise.reject(err);
            });
    }

    callViewFunction({ func = "", args = [], contract }) {
        Log.info(
            `%c【 VIEW START 】%c contract: ${contract}, func: ${func}, args: ${args}`,
            prefix,
            suffix
        );
        if (args) {
            return this.contractMap[contract].methods[func](...args)
                .call()
                .then((res) => {
                    Log.info(
                        `%c【 VIEW END 】 %c contract: ${contract}, func: ${func}, args: ${args}, res: ${res} ===`,
                        prefix,
                        suffix
                    );
                    return res;
                })
                .catch((err) => {
                    Log.error(
                        `%c【 VIEW END ERROR 】%c contract: ${contract}, func: ${func}, args: ${args},err: ${err}`,
                        prefix,
                        suffix
                    );
                    this.errorHandler(err);
                    return Promise.reject(err);
                });
        } else {
            Log.error(
                `%c【 VIEW START ERROR 】 %c err: no args`,
                prefix,
                suffix
            );

            return Promise.reject(" 【 VIEW START ERROR 】 err: no args");
        }
    }

    beforeTransaction({
        func = "",
        from = "",
        to = "",
        value = 0,
        args = [],
        contract,
        gas,
    }) {
        Log.info(
            `%c【 CHECK START 】 %c contract: ${contract}, func: ${func}, args: ${args}, from: ${from}, to: ${to}`,
            prefix,
            suffix
        );
        const encodedData = this.encodeCryptoData({ contract, func, args });
        const validData = {
            from: from && from !== "" ? from : this.defaultMyAddress,
            to: to,
            value,
            data: encodedData,
            gas: gas,
        };

        return this.web3JS.eth
            .call(validData)
            .then((res) => {
                Log.info(
                    `%c【 CHECK END 】 %c contract: ${contract}, func: ${func}, args: ${args}, from: ${from}, to: ${to}, res: ${res}`,
                    prefix,
                    suffix
                );

                return {
                    pass: true,
                    successData: res,
                };
            })
            .catch((err, rrr) => {
                Log.error(
                    `%c【 CHECK END ERROR 】%c contract: ${contract}, func: ${func}, args: ${args}, from: ${from}, to: ${to}, res: ${err}, rrr: ${rrr}`,
                    prefix,
                    suffix
                );

                this.errorHandler(err);
                return {
                    pass: false,
                    error: err,
                };
            });
    }
    //
    getUSDTBalance() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "balanceOf",
            contract: "usdt",
            args: [this.defaultMyAddress],
            to: UsdtAddress,
            from: this.defaultMyAddress,
        });
    }
    getDAOBalance() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "balanceOf",
            contract: "dao",
            args: [this.defaultMyAddress],
            to: DaoAddress,
            from: this.defaultMyAddress,
        });
    }
    // ----------- AI --------------------------
    getAIBalance() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "balanceOf",
            contract: "ai",
            args: [this.defaultMyAddress],
            to: AITokenAddress,
            from: this.defaultMyAddress,
        });
    }

    getApprovedCountAI() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "allowance",
            contract: "ai",
            args: [this.defaultMyAddress, InvestAIAddress],
            to: AITokenAddress,
            from: this.defaultMyAddress,
        });
    }

    approveAIToInvest() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callModifyFunction({
            func: "approve",
            contract: "ai",
            // TODO: 最大授权
            args: [InvestAIAddress, maxApprove],
            to: AITokenAddress,
            from: this.defaultMyAddress,
        });
    }

    getLockedAmount() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "getLockedAmount",
            contract: "ai",
            args: [this.defaultMyAddress],
            to: AITokenAddress,
            from: this.defaultMyAddress,
        });
    }

    buyAI({
        vipLevel = 0,
        inviter = "0x0000000000000000000000000000000000000000",
    }) {
        Log.info(`=== buyAI vipLevel: ${vipLevel}, inviter: ${inviter} ===`);
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callAbiFunction({
            func: "investAIT",
            contract: "investAI",
            args: [vipLevel, inviter],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        }); // .catch(err => Log.error('=== buyAI error ===', err))
    }

    getUserInfo() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "getUserInfo",
            contract: "investAI",
            args: [this.defaultMyAddress],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        }).catch((err) => Log.error("=== getUserInfo err ==", err));
    }

    pendingInviteRewards() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "pendingInviteRewards",
            contract: "investAI",
            args: [this.defaultMyAddress],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        }).catch((err) => Log.error("=== pendingInviteRewards err ==", err));
    }

    getInvestProducts(level = 0) {
        return this.callViewFunction({
            func: "getVIPInfo",
            contract: "investAI",
            args: [level],
            to: InvestAIAddress,
        }).catch((err) => Log.error("=== getInvestProducts err ==", err));
    }

    claimRewards() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callModifyFunction({
            func: "claimRewards",
            contract: "investAI",
            args: [],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        });
        // .catch(err => Log.error('=== claimRewards err ==', err))
    }

    claimInviteRewards() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callModifyFunction({
            func: "claimInviteRewards",
            contract: "investAI",
            args: [],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        });
        // .catch(err => Log.error('=== claimRewards err ==', err))
    }

    getPendingRewards() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "pendingRewards",
            contract: "investAI",
            args: [this.defaultMyAddress],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        }).catch((err) => Log.error("=== getPendingRewards err ==", err));
    }

    userRegistrations(address) {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        if (address === DefaultUserAddress) {
            return Promise.resolve(true);
        }

        return this.callViewFunction({
            func: "userRegistrations",
            contract: "investAI",
            args: [address],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        }).catch((err) => Log.error("=== userRegistrations err ==", err));
    }

    getUserInfoLite() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "getUserInfo",
            contract: "investAI",
            args: [this.defaultMyAddress],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        }).catch((err) => Log.error("=== getUserInfo err ==", err));

        // return this.callViewFunction({
        //     func: "getUserInfoLite",
        //     contract: "investAI",
        //     args: [this.defaultMyAddress],
        //     to: InvestAIAddress,
        //     from: this.defaultMyAddress,
        // }).catch((err) => Log.error("=== getUserInfoLite err ==", err));
    }

    userLastInvestFinished() {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "userLastInvestFinished",
            contract: "investAI",
            args: [this.defaultMyAddress],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        }).catch((err) => Log.error("=== userLastInvestFinished err ==", err));
    }

    getUserInfoTeams(level) {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "getUserInfoTeams",
            contract: "investAI",
            args: [this.defaultMyAddress, level],
            to: InvestAIAddress,
            from: this.defaultMyAddress,
        }).catch((err) => Log.error("=== getUserInfoTeams err ==", err));
    }
    // ----------- OXC ---以上-----------------------
    // ----------- AI IDO --------------------------

    getUserIDOInfo(contract, to) {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "getUserIDOInfo",
            contract: contract,
            args: [this.defaultMyAddress],
            to: to,
        }).catch((err) => Log.error("=== getUserIDOInfo err ==", err));
    }
    getSoldPieces(contract, to) {
        return this.callViewFunction({
            func: "soldPieces",
            contract: contract,
            args: [],
            to: to,
        }).catch((err) => Log.error("=== getSoldPieces err ==", err));
    }
    getTotalPieces(contract, to) {
        return this.callViewFunction({
            func: "totalPieces",
            contract: contract,
            args: [],
            to: to,
        }).catch((err) => Log.error("=== getTotalPieces err ==", err));
    }
    getEachPieceAIAmount(contract, to) {
        return this.callViewFunction({
            func: "eachPieceAITAmount",
            contract: contract,
            args: [],
            to: to,
        }).catch((err) => Log.error("=== getEachPieceAITAmount err ==", err));
    }
    getEachPiecePriceUSDT(contract, to) {
        return this.callViewFunction({
            func: "eachPiecePriceUSDT",
            contract: contract,
            args: [],
            to: to,
        }).catch((err) => Log.error("=== getEachPiecePriceUSDT err ==", err));
    }
    getEachPiecePriceDAO(contract, to) {
        return this.callViewFunction({
            func: "eachPiecePriceAI",
            contract: contract,
            args: [],
            to: to,
        }).catch((err) => Log.error("=== getEachPiecePriceAI err ==", err));
    }
    approveUSDT2Contract(to) {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");
        return this.callModifyFunction({
            func: "approve",
            contract: "usdt",
            // TODO: 最大授权
            args: [to, maxApprove],
            to: UsdtAddress,
            from: this.defaultMyAddress,
        });
    }
    approveDAO2Contract(to) {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");
        return this.callModifyFunction({
            func: "approve",
            contract: "dao",
            // TODO: 最大授权
            args: [to, maxApprove],
            to: DaoAddress,
            from: this.defaultMyAddress,
        });
    }

    joinAIIDO(contract, to) {
        Log.info(`=== joinAIIDO ===`);
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");
        return this.callModifyFunction({
            func: "joinIDO",
            contract: contract,
            args: [],
            to: to,
            from: this.defaultMyAddress,
        });
        // .catch(err => Log.error('=== joinOXCIDO error ===', err))
    }

    setStartBlock(contract, to) {
        Log.info(`=== setStartBlock ===`);
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");
        return this.callModifyFunction({
            func: "setStartBlock",
            contract: contract,
            args: [38742386],
            to: to,
            from: this.defaultMyAddress,
        });
        // .catch(err => Log.error('=== joinOXCIDO error ===', err))
    }

    getIDOPaused(contract, to) {
        return this.callViewFunction({
            func: "paused",
            contract: contract,
            args: [],
            to: to,
        }).catch((err) => Log.error("=== getIDOPaused err ==", err));
    }

    setIDOPaused(contract, to) {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");
        return this.callModifyFunction({
            func: "setPause",
            contract: contract,
            args: [true],
            to: to,
            from: this.defaultMyAddress,
        });
    }

    getApprovedCountUSDTToIDO(to) {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "allowance",
            contract: "usdt",
            args: [this.defaultMyAddress, to],
            to: UsdtAddress,
            from: this.defaultMyAddress,
        });
    }

    getApprovedCountDAOToIDO(to) {
        if (!this.defaultMyAddress)
            return Promise.reject("no defaultMyAddress");

        return this.callViewFunction({
            func: "allowance",
            contract: "dao",
            args: [this.defaultMyAddress, to],
            to: UsdtAddress,
            from: this.defaultMyAddress,
        });
    }

    // ----------- AI ICO --------------------------
    getUserICOInfo() {
        return this.getUserIDOInfo("aiIco", AIICOAddress);
    }
    getICOSoldPieces() {
        return this.getSoldPieces("aiIco", AIICOAddress);
    }
    getICOTotalPieces() {
        return this.getTotalPieces("aiIco", AIICOAddress);
    }
    getICOEachPieceAIAmount() {
        return this.getEachPieceAIAmount("aiIco", AIICOAddress);
    }
    getICOEachPiecePriceUSDT() {
        return this.getEachPiecePriceUSDT("aiIco", AIICOAddress);
    }
    getICOEachPiecePriceDAO() {
        return this.getEachPiecePriceDAO("aiIco", AIICOAddress);
    }
    approveUSDT2ICO() {
        return this.approveUSDT2Contract(AIICOAddress);
    }
    approveDAO2ICO() {
        return this.approveDAO2Contract(AIICOAddress);
    }
    joinAIICO() {
        return this.joinAIIDO("aiIco", AIICOAddress);
    }
    setICOStartBlock() {
        return this.setStartBlock("aiIco", AIICOAddress);
    }
    getICOPaused() {
        return this.getIDOPaused("aiIco", AIICOAddress);
    }
    setICOPaused() {
        return this.setIDOPaused("aiIco", AIICOAddress);
    }
    getApprovedCountUSDTToICO() {
        return this.getApprovedCountUSDTToIDO(AIICOAddress);
    }
    getApprovedCountDAOToICO() {
        return this.getApprovedCountDAOToIDO(AIICOAddress);
    }
    // ----------- AI IEO --------------------------
    getUserIEOInfo() {
        return this.getUserIDOInfo("aiIeo", AIIEOAddress);
    }
    getIEOSoldPieces() {
        return this.getSoldPieces("aiIeo", AIIEOAddress);
    }
    getIEOTotalPieces() {
        return this.getTotalPieces("aiIeo", AIIEOAddress);
    }
    getIEOEachPieceAIAmount() {
        return this.getEachPieceAIAmount("aiIeo", AIIEOAddress);
    }
    getIEOEachPiecePriceUSDT() {
        return this.getEachPiecePriceUSDT("aiIeo", AIIEOAddress);
    }
    getIEOEachPiecePriceDAO() {
        return this.getEachPiecePriceDAO("aiIeo", AIIEOAddress);
    }
    approveUSDT2IEO() {
        return this.approveUSDT2Contract(AIIEOAddress);
    }
    approveDAO2IEO() {
        return this.approveDAO2Contract(AIIEOAddress);
    }
    joinAIIEO() {
        return this.joinAIIDO("aiIeo", AIIEOAddress);
    }
    setIEOStartBlock() {
        return this.setStartBlock("aiIeo", AIIEOAddress);
    }
    getIEOPaused() {
        return this.getIDOPaused("aiIeo", AIIEOAddress);
    }
    setIEOPaused() {
        return this.setIDOPaused("aiIeo", AIIEOAddress);
    }
    getApprovedCountUSDTToIEO() {
        return this.getApprovedCountUSDTToIDO(AIIEOAddress);
    }
    getApprovedCountDAOToIEO() {
        return this.getApprovedCountDAOToIDO(AIIEOAddress);
    }
}

const wallet = new Wallet();

export default wallet;

import Log from '../../utils/log';
import { defineComponent, ref } from 'vue';
import footerTab1Active from '../../assets/footer_tab1_active.png';
import footerTab1 from '../../assets/footer_tab1.png';
import footerTab2Active from '../../assets/footer_tab2_active.png';
import footerTab2 from '../../assets/footer_tab2.png';
const active = ref('选项1');
export default defineComponent({
  name: 'LayoutHeader',

  data() {
    return {
      active,
      footerTab1Active,
      footerTab1,
      footerTab2Active,
      footerTab2
    };
  },

  components: {},
  computed: {
    activeRoute() {
      return this.$router.currentRoute.value.path;
    },

    showBNav() {
      return this.$router.currentRoute.value.path == '/' || this.$router.currentRoute.value.path == '/me';
    }

  },

  mounted() {
    Log.info('router', this.$router, this.$route);
  },

  methods: {
    handleRoute(path) {
      if (this.activeRoute === path) return;
      this.$router.push({
        path,
        query: this.$router.currentRoute.value.query
      });
    }

  }
});
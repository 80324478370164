import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3bd50e3e"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "layout-container"
};
export function render(_ctx, _cache) {
  const _component_Head = _resolveComponent("Head");

  const _component_el_header = _resolveComponent("el-header");

  const _component_Main = _resolveComponent("Main");

  const _component_el_main = _resolveComponent("el-main");

  const _component_Footer = _resolveComponent("Footer");

  const _component_el_footer = _resolveComponent("el-footer");

  const _component_el_container = _resolveComponent("el-container");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
    default: _withCtx(() => [_createVNode(_component_el_header, null, {
      default: _withCtx(() => [_createVNode(_component_Head)]),
      _: 1
    }), _createVNode(_component_el_main, null, {
      default: _withCtx(() => [_createVNode(_component_Main)]),
      _: 1
    }), _withDirectives(_createVNode(_component_el_footer, null, {
      default: _withCtx(() => [_createVNode(_component_Footer)]),
      _: 1
    }, 512), [[_vShow, _ctx.showFooter]])]),
    _: 1
  })]);
}